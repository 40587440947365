export function useExchanges() {
  const userStore = useUserStore();

  const exchanges = [
    {
      key: "binance",
      name: "Binance",
      avatar: "binance-avatar.png",
      localLogo: "binance.svg",
      logoSize: "xl",
      international: true,
      integrated: true,
      description: "World's largest crypto exchange",
    },
    {
      key: "coinbase",
      name: "Coinbase",
      avatar: "coinbase-avatar.png",
      localLogo: "coinbase-dark.svg",
      logoSize: "xl",
      international: true,
      integrated: true,
      description:
        "Our mission is to increase economic freedom in the world. Join us and make an impact at a global scale.",
    },
    {
      key: "bybit",
      name: "Bybit",
      avatar: "bybit-avatar.png",
      international: true,
    },
    {
      key: "huobi",
      name: "Huobi",
      avatar: "huobi-avatar.png",
      international: true,
    },
    {
      key: "firi",
      name: "Firi",
      avatar: "Firi.svg",
      localLogo: "Firi.svg",
      size: "xs",
      integrated: true,
      description: "Firi er en nordisk kryptovalutabørs",
    },
    {
      key: "kaupang",
      name: "Kaupang",
      avatar: "Arcane.svg",
      localLogo: "Arcane.svg",
      size: "xs",
    },
  ];

  const getExchange = computed(() => (exchange) => {
    return exchanges.find((ex) => ex.key == exchange);
  });

  const international = computed(() =>
    exchanges.filter((ex) => ex.international),
  );

  const others = computed(() => exchanges.filter((ex) => !ex.international));

  const integrated = computed(() => exchanges.filter((ex) => ex.integrated));

  return {
    international,
    others,
    getExchange,
    exchanges,
    integrated,
  };
}
